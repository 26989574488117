<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <ProductsList />
  </Layout>
</template>

<script>
import Layout from '@/views/Layout'
import ProductsList from '@/components/Products/ProductsList'

export default {
  name: 'ProductsIndexView',
  components: {
    Layout,
    ProductsList
  },
  computed: {
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Cadastros',
        to: { name: 'RegistersIndex' }
      },
      {
        text: 'Produtos',
        to: { name: 'ProductsIndex' },
        active: true
      }
    ]
  }
}
</script>
