var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button-toolbar',{staticClass:"mb-3",attrs:{"key-nav":""}},[_c('b-button',{attrs:{"variant":"primary","size":"sm","to":{ name: 'ProductRegister' }}},[_c('span',{staticClass:"fas fa-plus mr-1"}),_vm._v(" Cadastrar Produto ")])],1),(_vm.error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('span',{staticClass:"fas fa-exclamation-triangle mr-1"}),_vm._v(" "+_vm._s(_vm.error)+" ")]):_c('b-table',{ref:"productsList",attrs:{"fields":_vm.fields,"items":_vm.provider,"striped":"","hover":"","show-empty":"","busy":_vm.isBusy},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:(_vm.getColumnStyle(field.key))})})}},{key:"cell(image)",fn:function(ref){
var image = ref.value;
var item = ref.item;
return [_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.getProductImage(image),"alt":item.name}})]}},{key:"cell(price)",fn:function(ref){
var price = ref.value;
return [_vm._v(" R$ "+_vm._s(price.toFixed(2))+" ")]}},{key:"cell(store)",fn:function(ref){
var store = ref.value;
return [_vm._v(" "+_vm._s(store.name)+" ")]}},{key:"cell(status)",fn:function(ref){
var status = ref.value;
return [_c('Status',{attrs:{"value":status}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('BaseListActions',{attrs:{"item":item},on:{"edit":_vm.onEdit,"remove":_vm.onRemove,"updateStatus":_vm.onUpdateStatus}})]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"empty"},slot:"empty"},[_vm._v("Nenhum produto cadastrado")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }